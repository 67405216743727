/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1wzdrt2 --style4 --full" name={"uvod"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1300}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 mt--10 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--86" style={{"maxWidth":1276}} content={"<span style='color: white'>Obuvnictví, brašnářství & sedlařství Praha</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 mt--30" content={"Nabídka služeb"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"informace"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --left" anim={"2"} animS={"3"} style={{"maxWidth":480}}>
              
              <Title className="title-box fs--72" content={"Kvalitní příprava a zpracování"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/134/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/134/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/134/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/134/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/134/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/134/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"informace-2"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box fs--36" content={"Výherce ceny Zlatá Jehla 2022"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box fs--36" content={"Více než 10 let zkušeností"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box fs--36" content={"Kvalitní příprava kůže"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1aato59 pb--60 pt--60" name={"kontakt"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--10 pt--10" anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--62 title-box--invert" style={{"maxWidth":1276}} content={"Kontaktovat"}>
              </Title>

              <Button className="btn-box btn-box--hvr2" content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"o-nas"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2 mb--10 mt--10" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--36" style={{"maxWidth":383}} content={"<span style=\"color: var(--color-dominant);\">Tomáš Kamenovský</span><br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">+420 797 811 2X0<br>info@vase-stranky.com<br>IČ: 12345678</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":495}} content={"<span style=\"color: var(--color-dominant);\">Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text.</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}